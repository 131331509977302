.form-group.required .form-control-label::before {
  content: "*";
  color: #c00; }

.login-page {
  margin-top: 1em; }
  .login-page .login-form-nav {
    margin-bottom: 1.25em; }
    .login-page .login-form-nav .tab-pane {
      margin-top: 1em; }
  .login-page .login {
    margin-bottom: 0.938em; }
    .login-page .login .error-message-form {
      padding: 0.625em;
      background-color: #c00;
      color: #fff;
      border-radius: 0.188em; }

.registration-page .privacy-policy {
  margin-top: 1rem; }

.login-banner {
  background-image: url("../images/account.jpg");
  background-position-y: 40%; }

.equal-height .card {
  width: 100%; }

.track-order-header {
  font-size: 1.75rem; }

.request-password-title {
  font-size: 1.5rem; }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.form-group.required .form-control-label::before {
  content: "*";
  color: #c00; }

.form-group > span:not(.info-icon) {
  font-size: 80%; }

.form-control {
  color: var(--color-grey6);
  background-color: var(--color-white); }

:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: var(--color-grey5); }

::placeholder,
.form-control::placeholder {
  color: var(--color-grey5); }

.floating-label {
  position: relative;
  margin-bottom: 0.625rem; }
  .floating-label .form-control {
    height: 3.75rem; }
  .floating-label label {
    font-size: 0.875rem;
    position: absolute;
    top: 0.9375rem;
    left: 0.75rem;
    margin: 0;
    opacity: 0;
    transition: all .3s ease; }
  .floating-label input {
    transition: all .3s ease; }
    .floating-label input:-webkit-autofill, .floating-label input:not(:placeholder-shown) {
      padding: 1.5rem 2.25rem 0.375rem 0.75rem;
      color: var(--color-black); }
      .floating-label input:-webkit-autofill ~ label, .floating-label input:not(:placeholder-shown) ~ label {
        transform: translateY(-0.625rem);
        opacity: 0.7;
        color: var(--color-grey5); }
  .floating-label select {
    color: var(--color-grey5);
    transition: all .3s ease; }
  .floating-label.focused select {
    padding: 1.5rem 2.25rem 0.375rem 0.75rem;
    color: var(--color-black); }
    .floating-label.focused select + label {
      transform: translateY(-0.625rem);
      opacity: 0.7;
      color: var(--color-grey5); }

form.floating .btn-form-inline {
  height: calc(100% - 0.625rem); }

.login-form-nav .nav-item > .nav-link {
  color: var(--color-black); }

/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	PX to REM
	---------------------------------------------------------------
*/
/*	---------------------------------------------------------------
	Maps for styleguide and utility classes
	---------------------------------------------------------------
*/
.form-group.required .form-control-label::before {
  content: "*";
  color: #c41f40; }

.form-group > span:not(.info-icon) {
  font-size: 80%; }

.form-control {
  color: var(--color-grey6);
  background-color: var(--color-white); }

:-ms-input-placeholder,
.form-control:-ms-input-placeholder {
  color: var(--color-grey5); }

::placeholder,
.form-control::placeholder {
  color: var(--color-grey5); }

.floating-label {
  position: relative;
  margin-bottom: 0.625rem; }
  .floating-label .form-control {
    height: 3.75rem; }
  .floating-label label {
    font-size: 0.875rem;
    position: absolute;
    top: 0.9375rem;
    left: 0.75rem;
    margin: 0;
    opacity: 0;
    transition: all .3s ease; }
  .floating-label input {
    transition: all .3s ease; }
    .floating-label input:-webkit-autofill, .floating-label input:not(:placeholder-shown) {
      padding: 1.5rem 2.25rem 0.375rem 0.75rem;
      color: var(--color-black); }
      .floating-label input:-webkit-autofill ~ label, .floating-label input:not(:placeholder-shown) ~ label {
        transform: translateY(-0.625rem);
        opacity: 0.7;
        color: var(--color-grey5); }
  .floating-label select {
    color: var(--color-grey5);
    transition: all .3s ease; }
  .floating-label.focused select {
    padding: 1.5rem 2.25rem 0.375rem 0.75rem;
    color: var(--color-black); }
    .floating-label.focused select + label {
      transform: translateY(-0.625rem);
      opacity: 0.7;
      color: var(--color-grey5); }

form.floating .btn-form-inline {
  height: calc(100% - 0.625rem); }

.form-control {
  color: #5e656b;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 10px 22px;
  background-color: var(--color-white);
  border-width: 1px;
  border-radius: 0.5rem; }
  .form-control:focus {
    box-shadow: 0 0 0 0.2rem rgba(161, 212, 0, 0.7); }

.was-validated .form-control:invalid,
.form-control.is-invalid {
  border: 2px solid #c41f40;
  background-image: none;
  padding-right: 22px; }

.form-control-label {
  font-size: 1rem;
  line-height: 1.5rem;
  font-family: "BrandonGrotesque-WebBold";
  color: #5e656b; }

.invalid-feedback {
  color: #c41f40;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0.5rem; }

textarea.form-control {
  border: 1px solid #5e656b; }

.custom-control-label {
  font-size: 1rem;
  line-height: 1.5rem;
  color: #5e656b; }

.custom-checkbox .custom-control-label::after, .custom-checkbox .custom-control-label::before {
  border: 1px solid #7b7b7b;
  width: 0.75rem;
  height: 0.75rem;
  top: 0.31rem;
  border-radius: 0.125rem;
  left: -1.25rem; }

.custom-checkbox.required .custom-control-label::after, .custom-checkbox.required .custom-control-label::before {
  border: 2px solid #c41f40; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  border: 1px solid #38a6c4;
  background-color: #38a6c4;
  width: 0.75rem;
  height: 0.75rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
  background-size: 8px;
  background-position: 1px 1px;
  border: 1px solid #38a6c4; }

.custom-radio .custom-control-label::before {
  border: 1px solid #7b7b7b; }

.custom-radio .custom-control-label::before,
.custom-radio .custom-control-label::after {
  width: 12px;
  height: 12px;
  top: 0.4rem;
  left: -1.25rem; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
  border-color: #7b7b7b;
  background-color: #fff; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  border: 1px solid #7b7b7b;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2338A6C4'/%3e%3c/svg%3e");
  background-size: 8px; }

.form-group > span:not(.info-icon) {
  font-size: 0.75rem;
  color: #a5a5a5; }

.content {
  height: 100%;
  margin: 0 auto;
  padding: 30px;
  position: relative; }

.nav-item {
  width: 50%; }

.nav-pills .nav-link {
  font-weight: bold;
  padding-top: 13px;
  text-align: center;
  background: #343436;
  color: #fff;
  border-radius: 30px;
  height: 100px; }

.nav-pills .nav-link.active {
  background: #EFF6FA;
  color: #000; }

.tab-content {
  position: relative;
  /* width: 450px; */
  height: auto;
  margin-top: -50px;
  background: #fff;
  color: #000;
  border-radius: 30px;
  z-index: 1000;
  padding: 30px; }

.tab-content form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column; }

.inner-login-page {
  max-width: 66.66667%;
  margin: auto; }

.nav-item .nav-link:hover,
.nav-item .nav-link:focus,
.nav-item.show .nav-link {
  /* background-color: var(--skin-menu-color-1-invert); */
  /* color: var(--skin-menu-color-1) !important; */ }

.focus-speaciality-options, .dropdown-error-msg1, .dropdown-error-msg, .dropdown-error-msg-recaptcha {
  margin-left: 0px !important; }

.dropdown-error-msg, .dropdown-error-msg1, .dropdown-error-msg2, .dropdown-error-msg-recaptcha {
  color: #c41f40;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0.5rem; }

.focus-speaciality, .business_location, .where_did {
  width: 175px; }

@media (max-width: 1000px) {
  .inner-login-page {
    max-width: 100%; } }
